// src/LearningManagementSystem.js
import React from 'react';

function LearningManagementSystem() {
    return (
        <div>
            <h1>Learning Management System</h1>
            <p>Content for Learning Management System page.</p>
        </div>
    );
}

export default LearningManagementSystem;
