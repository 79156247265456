import ep1 from '../api/ep1';
import React, { useEffect, useState, useRef } from 'react';
import global1 from './global1';
import { Button, Box, Paper, Container, Grid, TextField } from '@mui/material';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import AddUserModal from './Addmclassnew';
import AddUserModalBulk from './Addmclassnewbulk';
import EditUserModal from '../Crud/Edit';
import DeleteUserModal from '../Crud/Delete';
import ExportUserModal from './Export';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart } from '@mui/x-charts/PieChart';

import { useNavigate } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


function ViewPage() {

    const fromdateref=useRef();
    const todateref=useRef();
    const pcoderef=useRef();
    const semref=useRef();
    const yearref=useRef();
    const sectionref=useRef();

  
    const [rows, setRows] = useState([]);
    const [results, setResults] = useState([]);
    const [second, setSecond] = useState([]);
    const [openAdd, setOpenAdd] = useState(false);
    const [openAddBulk, setOpenAddBulk] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openExport, setOpenExport] = useState(false);
    const [selectedUser, setSelectedUser] = useState();
    const [newUser, setNewUser] = useState({
      coursecode: '', coursetitle: '', year: '', coursetype: '', duration: '', offeredtimes: '', imagelink: '',studentsenrolled:'',
      price: '', category: '', department: '', coursehours: '', totalstudents: '', studentscompleted: '', dateadded: ''
    });

    const navigate = useNavigate();

    const user=global1.user;
    const token=global1.token;
    const colid=global1.colid;
    const name=global1.name;

    const handleDeleteClick = async (id) => {
        alert(id);
        const response = await ep1.get('/api/v2/deleteaddoncbyfac', {
            params: {
                id: id,
                token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiZGVtb0BjYW1wdXMudGVjaG5vbG9neSIsImNvbGlkIjoiMzAiLCJpYXQiOjE3MTY3ODk5NTEsImV4cCI6MTcxNzUwOTk1MX0.eXO0DAHibVppz9hj2LkIEE3nMY8xPNxg1OmasdRus1s",
                user: "demo@campus.technology"
            }

        });
        alert(response.data.status);
        const a=await fetchViewPage();
      };

      const onButtonClick = async(e, row) => {
        e.stopPropagation();
        //do whatever you want with the row
        //alert(row._id);
        const response = await ep1.get('/api/v2/deleteclassnewbyfac', {
            params: {
                id: row._id,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        const a = await fetchViewPage();
    };

    const onButtonClickgo = async(e, row) => {
      e.stopPropagation();
      //do whatever you want with the row
      //alert(row._id);
      global1.faccoursename=row.course;
      global1.faccoursecode=row.coursecode;
      global1.lmsyear=row.year;
      global1.classid=row._id;
      global1.facprogram=row.program;
      global1.facprogramcode=row.programcode;
      global1.facsemester=row.semester;
      global1.facsection=row.section;
      global1.facclassdate=row.classdate;
      
      navigate('/dashmattendancenew');
    
  };

  const onButtonClickrep = async(e, row) => {
    e.stopPropagation();
    //do whatever you want with the row
    //alert(row._id);
    global1.faccoursename=row.course;
    global1.faccoursecode=row.coursecode;
    global1.lmsyear=row.year;
    global1.classid=row._id;
    global1.facprogram=row.program;
    global1.facprogramcode=row.programcode;
    global1.facsemester=row.semester;
    global1.facsection=row.section;
    global1.facclassdate=row.classdate;
    
    navigate('/dashmattccode');
  
};

    const columns = [
        // { field: '_id', headerName: 'ID' },
    
//      {
// field:'year',
// headerName:'Academic year',
// type:'dropdown',
// width:200,
// editable:true,
// valueFormatter: (params) => {
// if (params.value) {
// return params.value;
// } else {
// return '';
// }
// }
//  },
// {
// field:'program',
// headerName:'Program',
// type:'text',
// width:200,
// editable:true,
// valueFormatter: (params) => {
// if (params.value) {
// return params.value;
// } else {
// return '';
// }
// }
//  },
// {
// field:'programcode',
// headerName:'Program code',
// type:'text',
// width:200,
// editable:true,
// valueFormatter: (params) => {
// if (params.value) {
// return params.value;
// } else {
// return '';
// }
// }
//  },
{
field:'course',
headerName:'Course',
type:'text',
width:200,
editable:true,
valueFormatter: (params) => {
if (params.value) {
return params.value;
} else {
return '';
}
}
 },
{
field:'coursecode',
headerName:'Course code',
type:'text',
width:200,
editable:true,
valueFormatter: (params) => {
if (params.value) {
return params.value;
} else {
return '';
}
}
 },
// {
// field:'semester',
// headerName:'Semester',
// type:'text',
// width:200,
// editable:true,
// valueFormatter: (params) => {
// if (params.value) {
// return params.value;
// } else {
// return '';
// }
// }
//  },
// {
// field:'section',
// headerName:'Section',
// type:'text',
// width:200,
// editable:true,
// valueFormatter: (params) => {
// if (params.value) {
// return params.value;
// } else {
// return '';
// }
// }
//  },
{
field:'classdate',
headerName:'Class date',
type:'date',
width:200,
editable:true,
valueGetter: (params) => {
if (!params.value) {
return new Date();
}
return new Date(params.value);
 },
valueFormatter: params => dayjs(params?.value).format('DD/MM/YYYY'),
 },
{
field:'classtime',
headerName:'Class time',
type:'text',
width:200,
editable:true,
valueFormatter: (params) => {
if (params.value) {
return params.value;
} else {
return '';
}
}
 },
{
field:'topic',
headerName:'Topic',
type:'text',
width:200,
editable:true,
valueFormatter: (params) => {
if (params.value) {
return params.value;
} else {
return '';
}
}
 },
{
field:'module',
headerName:'Module',
type:'text',
width:200,
editable:true,
valueFormatter: (params) => {
if (params.value) {
return params.value;
} else {
return '';
}
}
 },
{
field:'link',
headerName:'Link',
type:'text',
width:200,
editable:true,
valueFormatter: (params) => {
if (params.value) {
return params.value;
} else {
return '';
}
}
 },
{
field:'classtype',
headerName:'Class type',
type:'dropdown',
width:200,
editable:true,
valueFormatter: (params) => {
if (params.value) {
return params.value;
} else {
return '';
}
}
 },

  
        //   { field: 'actions', headerName: 'Actions', width: 600, renderCell: (params) => {
        //     return (
        //       <table>
        //         <tr>
        //           <td>
        //           <Button
        //         onClick={(e) => onButtonClick(e, params.row)}
        //         variant="contained"
        //       >
        //         Delete
        //       </Button>
        //           </td>
        //           <td width="10px"></td>
        //           <td>
        //           <Button
        //         onClick={(e) => onButtonClickgo(e, params.row)}
        //         variant="contained"
        //       >
        //         Attendance
        //       </Button>
        //           </td>
        //           <td width="10px"></td>
        //           <td>
        //           <Button
        //         onClick={(e) => onButtonClickrep(e, params.row)}
        //         variant="contained"
        //       >
        //         Report
        //       </Button>
        //           </td>
        //         </tr>
           
        //       </table>
        //     );
        //   } }
      ];


    const coursetitleref = useRef();
  
    const fetchViewPage = async () => {

        const date1=new Date(fromdateref.current.value);
        const date2=new Date(todateref.current.value);

        const ayear=yearref.current.value;
        const pcode=pcoderef.current.value;
        const semester=semref.current.value;
        const section=sectionref.current.value;




      const response = await ep1.get('/api/v2/getclassnewbypyss', {
        params: {
          token: token,
          colid: colid,
          user: user,
          date1: date1,
          date2: date2,
          year:ayear,
          programcode:pcode,
          semester:semester,
          section:section
        }
      });
      setRows(response.data.data.classes);
    };

    const getgraphdata = async () => {
      const response = await ep1.get('/api/v2/getclassnewcountbyfac', {
        params: {
          token: token,
          colid: colid,
          user: user
        }
      });
      setResults(response.data.data.classes);
    };

    const getgraphdatasecond = async () => {
      const response = await ep1.get('/api/v2/getclassnewsecondbyfac', {
        params: {
          token: token,
          colid: colid,
          user: user
        }
      });
      setSecond(response.data.data.classes);
    };

    const refreshpage=async()=> {
      fetchViewPage();
      getgraphdata();
      getgraphdatasecond();
    }
  
    useEffect(() => {
      //fetchViewPage();
      //getgraphdata();
      //getgraphdatasecond();
    }, []);
  
    const handleExport = () => {
      const ws = XLSX.utils.json_to_sheet(rows);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'ViewPage');
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(data, 'ViewPage_data.xlsx');
      setOpenExport(false);
    };
  
    const handleOpenAdd = () => {
      setOpenAdd(true);
    };

    const handleOpenAddBulk = () => {
        setOpenAddBulk(true);
      };
  
    const handleCloseAdd = () => {
      setOpenAdd(false);
      setNewUser({
        coursecode: '', coursetitle: '', year: '', coursetype: '', duration: '', offeredtimes: '', imagelink: '',
        price: '', category: '', department: '', coursehours: '', totalstudents: '', studentscompleted: '',studentsenrolled:'', dateadded: ''
      });
    };

    const handleCloseAddBulk = () => {
        setOpenAddBulk(false);
        setNewUser({
          coursecode: '', coursetitle: '', year: '', coursetype: '', duration: '', offeredtimes: '', imagelink: '',
          price: '', category: '', department: '', coursehours: '', totalstudents: '', studentscompleted: '',studentsenrolled:'', dateadded: ''
        });
      };
  
    const handleOpenEdit = (user) => {
      global1.coursetitle = user.coursetitle;
      global1.coursecode = user.coursecode;
      global1.duration=user.duration;
      global1.coursetype=user.coursetype;
      global1.dateadded=user.dateadded;
      setSelectedUser(user);
      setOpenEdit(true);

      //alert(user.coursetitle);
     
    };

   

    const handleOpenEdit1 =async (user) => {
    
            //const title=titleref.current.value;
            const year=user.year;
const program=user.program;
const programcode=user.programcode;
const course=user.course;
const coursecode=user.coursecode;
const semester=user.semester;
const section=user.section;
const classdate=new Date(user.classdate);
const classtime=user.classtime;
const topic=user.topic;
const module=user.module;
const link=user.link;
const classtype=user.classtype;

            //alert(coursetitle + ' - ' + studentscompleted);
             
     
            const response =await ep1.get('/api/v2/updateclassnewbyfac', {
            params: {
            id: user._id,
            user: user.user,
            token:token,
            name: user.name,
            colid: colid,
            year:year,
program:program,
programcode:programcode,
course:course,
coursecode:coursecode,
semester:semester,
section:section,
classdate:classdate,
classtime:classtime,
topic:topic,
module:module,
link:link,
classtype:classtype,

            status1:'Submitted',
            comments:''
            
            }
            });
    
            
    
            const a = await fetchViewPage();
           
            //alert(response.data.status);
  
  
        //alert(user.coursetitle);
       
      };
  
    const handleCloseEdit = () => {
      setOpenEdit(false);
      setSelectedUser(null);
    };
  
    const handleOpenDelete = (user) => {
      setSelectedUser(user);
      setOpenDelete(true);
    };
  
    const handleCloseDelete = () => {
      setOpenDelete(false);
      setSelectedUser(null);
    };
  
    const handleAddUser = () => {
      const newUserId = rows.length ? rows[rows.length - 1]._id + 1 : 1;
      const newRow = { ...newUser, _id: newUserId };
      setRows([...rows, newRow]);
      handleCloseAdd();
    };
  
    const handleEditUser = () => {
      const updatedRows = rows.map((row) =>
        row._id === selectedUser._id ? { ...selectedUser } : row
      );
      setRows(updatedRows);
      handleCloseEdit();
    };
  
    const handleDeleteUser = () => {
      const updatedRows = rows.filter((row) => row._id !== selectedUser._id);
      setRows(updatedRows);
      handleCloseDelete();
    };
  
    const handleInputChange = (event, field) => {
      const { value } = event.target;
      if (openAdd) {
        setNewUser({ ...newUser, [field]: value });
      } else if (openEdit) {
        setSelectedUser({ ...selectedUser, [field]: value });
      }
    };
  
    return (
      <React.Fragment>
        <Container maxWidth="100%" sx={{ mt: 4, mb: 4 }}>
        <Box display="flex" marginBottom={4} marginTop={2}>
           
           {/* <Button
             variant="contained"
             color="success"
             style={{ padding: '5px 10px', marginRight: '4px', fontSize: '12px', height: '30px', width: '80px' }}
             onClick={handleOpenAdd}
           >
             Add 
           </Button>
           <Button
             variant="contained"
             color="success"
             style={{ padding: '5px 10px', marginRight: '4px', fontSize: '12px', height: '30px', width: '80px' }}
             onClick={handleOpenAddBulk}
           >
             Bulk
           </Button> */}
           <Button
             variant="contained"
             color="primary"
             style={{ padding: '5px 10px', fontSize: '12px', marginRight: '4px', height: '30px', width: '80px' }}
             onClick={() => setOpenExport(true)}
           >
             Export
           </Button>

           <Button onClick={refreshpage}
             variant="contained"
             color="secondary"
             style={{ padding: '5px 10px', fontSize: '12px', height: '30px', width: '80px' }}
           >
             Refresh
           </Button>
         </Box>

         <br />
         <p>Program code</p>
<TextField id="outlined-basic"  type="text" sx={{ width: "100%"}} label=""  variant="outlined" inputRef={pcoderef} /><br /><br />

<p>Academic year</p>
<TextField id="outlined-basic"  type="text" sx={{ width: "100%"}} label=""  variant="outlined" inputRef={yearref} /><br /><br />

<p>Semester</p>
<TextField id="outlined-basic"  type="text" sx={{ width: "100%"}} label=""  variant="outlined" inputRef={semref} /><br /><br />

<p>Section</p>
<TextField id="outlined-basic"  type="text" sx={{ width: "100%"}} label=""  variant="outlined" inputRef={sectionref} /><br /><br />


         <Box display="flex" marginBottom={4} marginTop={2}>
         
         <LocalizationProvider dateAdapter={AdapterDayjs}><DatePicker label="From date" inputRef={fromdateref} sx={{ width: "300px", marginRight: 1}} /></LocalizationProvider><br /><br />
     
         <LocalizationProvider dateAdapter={AdapterDayjs}><DatePicker label="To date" inputRef={todateref} sx={{ width: "300px", marginRight: 5}} /></LocalizationProvider><br /><br />
         <Button
              variant="contained"
              color="success"
              style={{ padding: '5px 10px', marginRight: '4px', fontSize: '12px', height: '40px', width: '80px' }}
              onClick={fetchViewPage}
            >
              List  
            </Button>
           <br />
         
         </Box>



          <Grid container spacing={3}>

         



            <Grid item xs={12}>
              <Paper elevation={5} sx={{ p: 2, display: 'flex', flexDirection: 'column', width: '100%' }}>
              {/* <h1>Table Component</h1> */}
             


                <DataGrid getRowId={(row) => row._id} 
                
        rows={rows}
        columns={columns}
       
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        processRowUpdate={(updatedRow, originalRow) =>
            handleOpenEdit1(updatedRow)
          }
        pageSizeOptions={[10]}
        disableRowSelectionOnClick
      />
                {/* add button handler */}
                <AddUserModal
                  open={openAdd}
                  handleClose={handleCloseAdd}
                  handleInputChange={handleInputChange}
                  handleAddUser={handleAddUser}
                  newUser={newUser}
                />

                <AddUserModalBulk
                  open={openAddBulk}
                  handleClose={handleCloseAddBulk}
                  handleInputChange={handleInputChange}
                  handleAddUser={handleAddUser}
                  newUser={newUser}
                />
  
                <EditUserModal
                  open={openEdit}
                  handleClose={handleCloseEdit}
                  handleInputChange={handleInputChange}
                  handleEditUser={handleEditUser}
                  selectedUser={selectedUser}
                />
  
                <DeleteUserModal
                  open={openDelete}
                  handleClose={handleCloseDelete}
                  handleDeleteUser={handleDeleteUser}
                  selectedUser={selectedUser}
                />
  
                <ExportUserModal
                  open={openExport}
                  handleClose={() => setOpenExport(false)}
                  handleExport={handleExport}
                />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </React.Fragment>
    );
  }
  
  export default ViewPage;
