import React from 'react';

function NAAC() {
    return (
        <div>
            <h1>NAAC</h1>
            <p>Content for NAAC page.</p>
        </div>
    );
}

export default NAAC;
